import { Heading2, Button } from '@casavo/base-ui'
import cn from 'classnames'
import { useFormikContext } from 'formik'
import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import { useEffect } from 'react'
import * as Yup from 'yup'

import { AnimatedWrapper } from '@/components/AnimatedWrapper'
import FormikInput from '@/components/FormFields/FormikInput'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { marginBottom } from '@/shared-styles/styles.css'
import { isEnter } from '@/utils/keyboard-helper'
import { handleClick } from '@/utils/tracking/eventsHandlers'

import { FlowFooter } from '../../../FlowFooter'
import { FlowHeader } from '../../../FlowHeader'
import { UserAuthTouchPoint } from '../../../UserAuthentication'
import { loginBody, loginBodyMaxWidth } from '../../styles.css'

export const stepEmailInitialValues = { email: '' }
export const stepEmailValidation = (t: Translate) =>
  Yup.object().shape({
    email: Yup.string().email(t('common:invalid-email-message')).required(t('common:required-field-message')),
  })

export const StepEmail: React.FC<{
  back: () => void
  goToLogin: () => void
  next: VoidFunction
  touchPoint: UserAuthTouchPoint
}> = ({ back, goToLogin, next, touchPoint }) => {
  const { t } = useTranslation()
  const { isValid, validateForm } = useFormikContext()

  useEffect(() => {
    validateForm()
  }, [])

  return (
    <AnimatedWrapper>
      <FlowHeader
        onBackButtonClick={() => {
          back()
          handleClick('SignUp', 'Back', touchPoint)
        }}
      />
      <div className={cn(loginBody, loginBodyMaxWidth)}>
        <Heading2 className={sprinkles({ paddingBottom: '2xl' })}>{t('auth-area:SignupWithMail_Title')}</Heading2>
        <div className={marginBottom['2xl']}>
          <FormikInput
            label=""
            name="email"
            placeholder={t('auth-area:Input_Email_Placeholder')}
            type="email"
            onKeyDown={(e) => {
              if (isEnter(e)) {
                isValid ? next() : e.preventDefault()
              }
            }}
          />
        </div>

        <Button
          fullWidth
          className={sprinkles({ marginBottom: { mobile: 'xl' } })}
          data-testid="next-button-signup"
          disabled={!isValid}
          label={t('auth-area:SignupWithMail_CTA1')}
          type="button"
          onClick={() => {
            next()
            handleClick('SignUp', 'Continue', touchPoint)
          }}
        />
      </div>

      <FlowFooter
        ctaLabel={t('auth-area:LoginWithMail_CTA')}
        description={t('auth-area:ToLogin_Description')}
        handleCtaClick={() => {
          goToLogin()
          handleClick('SignUp', 'ToLoginWithMail', touchPoint)
        }}
      />
    </AnimatedWrapper>
  )
}
