import React from 'react'

import { useAuthentication } from '@/context/AuthProvider'
import { EnabledSocialLoginProvider } from '@/utils/auth/auth-client'
import { handleClick } from '@/utils/tracking/eventsHandlers'

import { UserAuthGtmCategory, UserAuthTouchPoint } from '..'

import { SocialLoginButton } from './SocialLoginButton'

type Props = {
  gtmCategory: UserAuthGtmCategory
  returnUrl: string
  touchPoint: UserAuthTouchPoint
}

export const SocialAuthentication: React.FC<Props> = ({ gtmCategory, returnUrl, touchPoint }) => {
  const { loginWithProvider } = useAuthentication()

  const clickOnProvider = (provider: EnabledSocialLoginProvider, eventlabel: string) => {
    return () => {
      handleClick(gtmCategory, eventlabel, touchPoint)
      loginWithProvider(provider, returnUrl).catch(console.error)
    }
  }

  return <SocialLoginButton provider="google" onClick={clickOnProvider('google', 'WithGoogle')} />
}
