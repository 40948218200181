import { Button, Caption, colorVariants, Description } from '@casavo/base-ui'
import { ColorsPalette } from '@casavo/base-ui/types/foundations/colors'
import cn from 'classnames'

import { Translated } from '@/components/Translated'
import { useDevice } from '@/context/Device'
import Icon, { IconKey } from '@/design-lib/components/Icons'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { noShrink } from '@/shared-styles/styles.css'
import { isEnter } from '@/utils/keyboard-helper'

import { calloutContent, calloutWrapper } from './styles.css'

type Props = {
  className?: string
  ctaLabel?: string
  description: string
  handleClick?: (e: React.KeyboardEvent | React.MouseEvent) => void
  icon?: IconKey
  iconColor?: ColorsPalette
  onClose?: VoidFunction
  title?: string
}

export const Callout: React.FC<Props> = ({
  className,
  ctaLabel,
  description,
  handleClick,
  icon,
  iconColor = 'greyscale600',
  onClose,
  title,
}) => {
  const device = useDevice()
  const IconComponent = Icon[icon]
  const iconSize = device === 'mobile' ? 15 : 20

  return (
    <div
      className={cn(calloutWrapper, className, sprinkles({ position: 'relative' }))}
      role="button"
      tabIndex={0}
      onClick={(e) => handleClick && handleClick(e)}
      onKeyDown={(e) => isEnter(e) && handleClick && handleClick(e)}
    >
      {onClose && (
        <button
          aria-label="close"
          className={cn(
            colorVariants.greyscale500,
            sprinkles({ padding: 's-m', position: 'absolute', right: 0, top: 0 })
          )}
          onClick={(e) => {
            onClose()
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <Icon.Close height={12} width={12} />
        </button>
      )}
      <div className={calloutContent}>
        {icon && (
          <IconComponent
            className={cn(
              colorVariants[iconColor],
              noShrink,
              sprinkles({ alignSelf: 'flex-start', marginTop: { mobile: 'xs' } })
            )}
            height={iconSize}
            width={iconSize}
          />
        )}

        <div>
          {title && (
            <Description
              strong
              className={sprinkles({
                marginBottom: { mobile: 'xs' },
              })}
            >
              {title}
            </Description>
          )}

          {description && (
            <Caption>
              <Translated i18Key={description} />
            </Caption>
          )}
        </div>
      </div>
      {ctaLabel && (
        <Button
          className={sprinkles({ alignSelf: 'flex-end', flexShrink: 0, marginLeft: 'auto' })}
          label={ctaLabel}
          styleVariant="tertiary"
        />
      )}
    </div>
  )
}
