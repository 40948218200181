import { Button, Heading2 } from '@casavo/base-ui'
import cn from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import { FormEventHandler, useState } from 'react'
import * as Yup from 'yup'

import { AnimatedWrapper } from '@/components/AnimatedWrapper'
import TextInput from '@/components/TextInput'
import { Translated } from '@/components/Translated'
import { useAuthentication } from '@/context/AuthProvider'
import { ToastError } from '@/design-lib/components/Toast/ToastError'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { marginBottom } from '@/shared-styles/styles.css'
import { RecoverPasswordUpdateError } from '@/utils/auth/auth-client'
import { handleShow } from '@/utils/tracking/eventsHandlers'

import { FlowHeader } from '../../../FlowHeader'
import { loginBody, loginBodyMaxWidth } from '../../styles.css'
import { PasswordRecoveryStep } from '../PasswordRecoveryStep'

const errorTitles: Record<keyof typeof RecoverPasswordUpdateError, string> = {
  GENERIC: 'common:form-error',
  NOT_VERIFIED: 'common:form-error',
  TOO_SIMPLE_PASSWORD: 'auth-area:SignupWithMail_Error_SimplePSW_Title',
}

const errorDescriptions: Record<keyof typeof RecoverPasswordUpdateError, string> = {
  GENERIC: undefined,
  NOT_VERIFIED: undefined,
  TOO_SIMPLE_PASSWORD: 'auth-area:SignupWithMail_Error_SimplePSW_Description',
}

const errorEvents: Record<keyof typeof RecoverPasswordUpdateError, string> = {
  GENERIC: 'GenericError',
  NOT_VERIFIED: 'GenericError',
  TOO_SIMPLE_PASSWORD: 'EasyPassword',
}

export const StepPassword: PasswordRecoveryStep = ({ className, next, touchPoint }) => {
  const { t } = useTranslation()
  const { recoverPassword } = useAuthentication()
  const [password, setPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState('')
  const [requestError, setRequestError] = useState<RecoverPasswordUpdateError | null>(null)
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const passwordValidator = Yup.string()
    .required(t('common:required-field-message'))
    .min(8, t('auth-area:SignupWithMail_Input_Password_Error_ShortPsw'))

  const confirmPasswordValidator = Yup.string()
    .required(t('common:required-field-message'))
    .oneOf([password, null], t('auth-area:SignupWithMail_Input_RepeatPassword_Error_NotMatch'))

  const validateForm = (password: string, confirmPassword: string) => {
    passwordValidator
      .validate(password)
      .then(() => confirmPasswordValidator.validate(confirmPassword))
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false))
  }

  const validatePassword = (password: string) =>
    passwordValidator
      .validate(password)
      .then(() => setPasswordError(''))
      .catch((e) => setPasswordError(e.message))

  const validateConfirmPassword = (password: string) =>
    confirmPasswordValidator
      .validate(password)
      .then(() => setConfirmPasswordError(''))
      .catch((e) => setConfirmPasswordError(e.message))

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    await recoverPassword
      .update(password)
      .then(() => {
        handleShow('RecoverPassword', 'Success', touchPoint)
      })
      .then(next)
      .catch((e) => {
        setRequestError(e.message)
        handleShow('AuthError', errorEvents[e.message])
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <AnimatedWrapper className={className}>
      <FlowHeader />
      <form className={cn(loginBody, loginBodyMaxWidth)} onSubmit={onSubmit}>
        <Heading2 className={sprinkles({ paddingBottom: '2xl' })}>
          <Translated i18Key="auth-area:NewPassword_Title" />
        </Heading2>
        <div className={marginBottom['m']}>
          <TextInput
            className={sprinkles({ border: 0 })}
            error={passwordError}
            name="password"
            placeholder={t('auth-area:SignupWithMail_Input_Password_Placeholder')}
            type="password"
            value={password}
            onBlur={(e) => {
              validatePassword(e.currentTarget.value)
              validateForm(e.currentTarget.value, confirmPassword)
            }}
            onChange={(e) => {
              setPassword(e.currentTarget.value)
              validatePassword(e.currentTarget.value)
              validateForm(e.currentTarget.value, confirmPassword)
            }}
          />
        </div>
        <div className={marginBottom['m']}>
          <TextInput
            error={confirmPasswordError}
            name="passwordConfirm"
            placeholder={t('auth-area:SignupWithMail_Input_RepeatPassword_Placeholder')}
            type="password"
            value={confirmPassword}
            onBlur={(e) => {
              validateConfirmPassword(e.currentTarget.value)
              validateForm(password, e.currentTarget.value)
            }}
            onChange={(e) => {
              setConfirmPassword(e.currentTarget.value)
              validateConfirmPassword(e.currentTarget.value)
              validateForm(password, e.currentTarget.value)
            }}
          />
        </div>

        <Button
          fullWidth
          className={sprinkles({ marginBottom: 'xl', marginTop: 'auto' })}
          disabled={!isFormValid || isSubmitting}
          label={t('auth-area:NewPassword_CTA')}
          type="submit"
        />
      </form>

      <ToastError
        description={errorDescriptions[requestError] && t(errorDescriptions[requestError])}
        error={!!requestError}
        setError={() => setRequestError(null)}
        title={errorTitles[requestError] && t(errorTitles[requestError])}
      />
    </AnimatedWrapper>
  )
}
