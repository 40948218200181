import { AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'

import { AuthFlow } from './flows/AuthFlow'
import { Flow } from './flows/Flow'
import { LoginWithPasswordFlow } from './flows/LoginWithPasswordFlow'
import { PasswordRecoveryFlow } from './flows/PasswordRecoveryFlow'
import { SignUpFlow } from './flows/SignUpFlow'

export type UserAuthTouchPoint =
  | 'FromMain'
  | 'FromFavorites'
  | 'FromSaveSearch'
  | 'FromSuggested'
  | 'FromBookVisit'
  | 'FromContactForm'
  | 'FromTeasingInbox'
  | 'FromLockedPriority'
  | 'FromVisitLockedPriority'
  | 'FromValuation'
  | 'FromPriorityNoResults'
  | 'FromPriceAnalysis'
export type UserAuthGtmCategory = 'SignUp' | 'Login' | 'Auth' | 'RecoverPassword'
export type UserAuthTouchPointTitle =
  | 'LoginPage_Title'
  | 'Login_SaveSearch_BottomSheet_Title'
  | 'Login_Favorites_BottomSheet_Title'
  | 'Login_Suggested_BottomSheet_Title'
  | 'Login_RequestVisit_BottomSheet_Title'
  | 'Login_PriorityDetail_BottomSheet_Title'
  | 'Login_TeasingInbox_Title'
  | 'Login_FromContactFrom_Title'
  | 'Login_PriorityDetail_BottomSheet_UnlockTitle'
  | 'BuyerContactFormModal_Title'

type Props = {
  className?: string
  conceptImage: React.ReactNode
  mainTitle?: UserAuthTouchPointTitle
  onLoggedIn?: VoidFunction
  returnUrl: string
  showCasavoLogo?: boolean
  touchPoint: UserAuthTouchPoint
}

export const UserAuthentication: React.FC<Props> = ({
  className,
  conceptImage,
  mainTitle,
  onLoggedIn,
  returnUrl,
  showCasavoLogo = true,
  touchPoint,
}) => {
  const defaultFlow = Flow.auth
  const [currentFlow, changeFlow] = useState<Flow>(defaultFlow)

  const backToDefaultFlow = () => changeFlow(defaultFlow)

  const flows: Record<keyof typeof Flow, React.ReactNode> = {
    auth: (
      <AuthFlow
        back={backToDefaultFlow}
        changeFlow={changeFlow}
        className={className}
        conceptImage={conceptImage}
        mainTitle={mainTitle}
        returnUrl={returnUrl}
        showCasavoLogo={showCasavoLogo}
        touchPoint={touchPoint}
      />
    ),
    passwordLogin: (
      <LoginWithPasswordFlow
        back={backToDefaultFlow}
        changeFlow={changeFlow}
        className={className}
        complete={onLoggedIn}
        touchPoint={touchPoint}
      />
    ),
    recover: (
      <PasswordRecoveryFlow
        back={() => changeFlow(Flow.passwordLogin)}
        className={className}
        complete={() => changeFlow(Flow.passwordLogin)}
        touchPoint={touchPoint}
      />
    ),
    signUp: (
      <SignUpFlow
        back={backToDefaultFlow}
        changeFlow={changeFlow}
        className={className}
        complete={onLoggedIn}
        touchPoint={touchPoint}
      />
    ),
  }

  return <AnimatePresence exitBeforeEnter>{flows[currentFlow]}</AnimatePresence>
}
