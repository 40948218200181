import { AnimatedWrapper } from '@/components/AnimatedWrapper'
import { CompletedFlowStepContent } from '@/components/auth/UserAuthentication/flows/CompletedFlowStepContent'

import { PasswordRecoveryStep } from '../PasswordRecoveryStep'

export const StepPasswordSaved: PasswordRecoveryStep = ({ className, next }) => {
  return (
    <AnimatedWrapper className={className}>
      <CompletedFlowStepContent
        description={'auth-area:NewPassword_Success_Description'}
        title={'auth-area:NewPassword_Success_Title'}
        onConfirm={next}
      />
    </AnimatedWrapper>
  )
}
