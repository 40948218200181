import { Heading2, Small, Button } from '@casavo/base-ui'
import cn from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { AnimatedWrapper } from '@/components/AnimatedWrapper'
import { Translated } from '@/components/Translated'
import { Divider } from '@/design-lib/components/Divider'
import Email from '@/design-lib/components/Icons/Email'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { handleClick } from '@/utils/tracking/eventsHandlers'

import { FlowFooter } from '../../FlowFooter'
import { FlowHeader } from '../../FlowHeader'
import { SocialAuthentication } from '../../SocialAuthentication'
import { UserAuthTouchPoint, UserAuthTouchPointTitle } from '../../UserAuthentication'
import { Flow } from '../Flow'
import { loginBody, loginBodyMaxWidth } from '../styles.css'

type Props = {
  back: () => void
  changeFlow: (flow: Flow) => void
  className?: string
  conceptImage: React.ReactNode
  mainTitle?: UserAuthTouchPointTitle
  returnUrl: string
  showCasavoLogo?: boolean
  touchPoint: UserAuthTouchPoint
}

export const AuthFlow: React.FC<Props> = ({
  changeFlow,
  className,
  conceptImage,
  mainTitle = 'LoginPage_Title',
  returnUrl,
  showCasavoLogo,
  touchPoint,
}) => {
  const { t } = useTranslation()
  const userAgent = typeof window !== 'undefined' && window.navigator.userAgent

  const isInstagramWebView = userAgent && userAgent.includes('Instagram')
  const isFacebookWebView = userAgent && /FBMD|FBAN|FBAV/.test(userAgent)
  const showSocialLogin = !isInstagramWebView && !isFacebookWebView

  return (
    <AnimatedWrapper className={className}>
      {showCasavoLogo && <FlowHeader />}
      <div className={cn(loginBody, loginBodyMaxWidth)}>
        <div className={sprinkles({ marginBottom: 'm', marginTop: showCasavoLogo ? 0 : 'l', textAlign: 'center' })}>
          {conceptImage}
        </div>

        <Heading2 className={sprinkles({ marginBottom: '2xl' })}>
          <Translated i18Key={`auth-area:${mainTitle}`} />
        </Heading2>

        {showSocialLogin && (
          <>
            <SocialAuthentication gtmCategory="Auth" returnUrl={returnUrl} touchPoint={touchPoint} />

            <div className={sprinkles({ alignItems: 'center', display: 'flex', width: '100%' })}>
              <Divider colorVariant="greyscale500" />
              <Small className={sprinkles({ flexShrink: 0, paddingX: 's' })}>{t('auth-area:Divider_Smalltext')}</Small>
              <Divider colorVariant="greyscale500" />
            </div>
          </>
        )}

        <Button
          className={sprinkles({ margin: '0 auto' })}
          Icon={() => <Email />}
          label={t('auth-area:LoginPage_WithMail_CTA')}
          styleVariant={showSocialLogin ? 'tertiary' : 'secondary'}
          onClick={() => {
            changeFlow(Flow.signUp)
            handleClick('Auth', 'ToSignUp', touchPoint)
          }}
        />
      </div>

      <FlowFooter
        ctaLabel={t('auth-area:LoginWithMail_CTA')}
        description={t('auth-area:ToLogin_Description')}
        handleCtaClick={() => {
          changeFlow(Flow.passwordLogin)
          handleClick('Auth', 'ToLoginWithMail', touchPoint)
        }}
      />
    </AnimatedWrapper>
  )
}
