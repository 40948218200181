import { Button, Heading2 } from '@casavo/base-ui'
import cn from 'classnames'
import { useFormikContext } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { AnimatedWrapper } from '@/components/AnimatedWrapper'
import Disclaimer from '@/components/Disclaimer'
import { DisclaimerRecaptcha } from '@/components/DisclaimerRecaptcha'
import FormikInput from '@/components/FormFields/FormikInput'
import { fadeInAnimation } from '@/components/framer-motion-variants'
import { Callout } from '@/design-lib/components/Callout/Callout'
import { ToastError } from '@/design-lib/components/Toast/ToastError'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { marginBottom } from '@/shared-styles/styles.css'
import { SignUpError } from '@/utils/auth/auth-client'
import { handleClick } from '@/utils/tracking/eventsHandlers'

import { UserAuthTouchPoint } from '../../..'
import { FlowHeader } from '../../../FlowHeader'
import { disclaimer, loginBody, loginBodyMaxWidth } from '../../styles.css'

export const stepPasswordInitialValues = { password: '', passwordConfirm: '' }
export const stepPasswordValidation = (t: Translate) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t('common:required-field-message'))
      .min(8, t('auth-area:SignupWithMail_Input_Password_Error_ShortPsw')),
    passwordConfirm: Yup.string()
      .required(t('common:required-field-message'))
      .oneOf([Yup.ref('password'), null], t('auth-area:SignupWithMail_Input_RepeatPassword_Error_NotMatch')),
  })

export const StepPassword: React.FC<{
  back: () => void
  clearError: Dispatch<SetStateAction<void>>
  error: SignUpError
  goToLogin: VoidFunction
  touchPoint: UserAuthTouchPoint
}> = ({ back, clearError, error, goToLogin, touchPoint }) => {
  const { t } = useTranslation()
  const { isSubmitting, isValid, validateForm } = useFormikContext()
  const submitCtaLabel = t('auth-area:SignupWithMail_CTA2')

  useEffect(() => {
    validateForm()
  }, [])

  const showCallout = error === SignUpError.ALREADY_EXISTS

  return (
    <AnimatedWrapper>
      <FlowHeader
        onBackButtonClick={() => {
          back()
          handleClick('SignUp', 'BackToMail', touchPoint)
        }}
      />
      <div className={loginBody}>
        <div className={loginBodyMaxWidth}>
          <Heading2 className={sprinkles({ paddingBottom: '2xl' })}>
            {t('auth-area:SignupWithMail_Password_Title')}
          </Heading2>
          <div className={marginBottom['xl']}>
            <FormikInput
              label=""
              name="password"
              placeholder={t('auth-area:SignupWithMail_Input_Password_Placeholder')}
              type="password"
            />
          </div>
          <div className={error !== SignUpError.ALREADY_EXISTS && marginBottom['xl']}>
            <FormikInput
              label=""
              name="passwordConfirm"
              placeholder={t('auth-area:SignupWithMail_Input_RepeatPassword_Placeholder')}
              type="password"
            />
          </div>
        </div>

        <AnimatePresence>
          {showCallout && (
            <motion.div animate="animate" exit="exit" initial="initial" variants={fadeInAnimation}>
              <Callout
                className={sprinkles({ marginBottom: 'xl', marginTop: 'xl' })}
                ctaLabel={t('auth-area:SignupWithMail_Alert_ExistingAccount_CTA')}
                description={t('auth-area:SignupWithMail_Alert_ExistingAccount_Description')}
                handleClick={goToLogin}
                icon="Info"
                title={t('auth-area:SignupWithMail_Alert_ExistingAccount_Title')}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <div className={disclaimer}>
          <Disclaimer cta={submitCtaLabel} />
          <br />
          <DisclaimerRecaptcha />
        </div>

        <Button
          fullWidth
          className={cn(loginBodyMaxWidth, sprinkles({ marginBottom: 'xl', marginTop: 'auto' }))}
          disabled={!isValid || isSubmitting}
          label={submitCtaLabel}
          type="submit"
        />

        {error === SignUpError.TOO_SIMPLE_PASSWORD ? (
          <ToastError
            description={t('auth-area:SignupWithMail_Error_SimplePSW_Description')}
            error={!!error}
            setError={() => clearError()}
            title={t('auth-area:SignupWithMail_Error_SimplePSW_Title')}
          />
        ) : (
          error !== SignUpError.ALREADY_EXISTS && <ToastError error={!!error} setError={() => clearError()} />
        )}
      </div>
    </AnimatedWrapper>
  )
}
