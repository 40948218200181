import { Button, Heading2, TextLinkButton } from '@casavo/base-ui'
import cn from 'classnames'
import { Form, Formik, FormikHelpers } from 'formik'
import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { AnimatedWrapper } from '@/components/AnimatedWrapper'
import FormikInput from '@/components/FormFields/FormikInput'
import { useAuthentication } from '@/context/AuthProvider'
import { ToastError } from '@/design-lib/components/Toast/ToastError'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { marginBottom, marginTop } from '@/shared-styles/styles.css'
import { LoginError } from '@/utils/auth/auth-client'
import { handleClick, handleShow } from '@/utils/tracking/eventsHandlers'

import { FlowFooter } from '../../FlowFooter'
import { FlowHeader } from '../../FlowHeader'
import { UserAuthTouchPoint } from '../../UserAuthentication'
import { CompletedFlowStepContent } from '../CompletedFlowStepContent'
import { Flow } from '../Flow'
import { loginBody, loginBodyMaxWidth } from '../styles.css'

const errorTitles: Record<keyof typeof LoginError, string> = {
  ALREADY_LOGGED_IN: 'common:form-error',
  GENERIC: 'common:form-error',
  INVALID_CREDENTIALS: 'auth-area:LoginWithMail_Error_WrongData_Title',
}

const errorDescriptions: Record<keyof typeof LoginError, string> = {
  ALREADY_LOGGED_IN: undefined,
  GENERIC: undefined,
  INVALID_CREDENTIALS: 'auth-area:LoginWithMail_Error_WrongData_Description',
}

const errorEvents: Record<keyof typeof LoginError, string> = {
  ALREADY_LOGGED_IN: 'GenericError',
  GENERIC: 'GenericError',
  INVALID_CREDENTIALS: 'WrongData',
}

const loginValidation = (t: Translate) => {
  const requiredInputMessage = t('common:required-field-message')
  return Yup.object().shape({
    identifier: Yup.string().email(t('common:invalid-email-message')).required(requiredInputMessage),
    password: Yup.string().required(requiredInputMessage),
  })
}

type LoginValidationSchema = Yup.InferType<ReturnType<typeof loginValidation>>

type Props = {
  back: () => void
  changeFlow: (flow: Flow) => void
  className?: string
  complete?: () => void
  touchPoint: UserAuthTouchPoint
}
export const LoginWithPasswordFlow: React.FC<Props> = ({ back, changeFlow, className, complete, touchPoint }) => {
  const { t } = useTranslation()
  const { loginWithCredentials } = useAuthentication()
  const [currentStep, setCurrentStep] = useState(0)
  const [requestError, setRequestError] = useState<LoginError | null>(null)

  const submit = (values: LoginValidationSchema, helpers: FormikHelpers<LoginValidationSchema>) => {
    const data = {
      identifier: values.identifier,
      password: values.password,
    }
    handleClick('Login', 'Submit', touchPoint)
    helpers.setSubmitting(true)
    loginWithCredentials(data)
      .then(() => {
        handleShow('Login', 'Success', touchPoint)
      })
      .then(() => complete && setCurrentStep(1))
      .catch((error) => {
        setRequestError(error.message)
        handleShow('AuthError', errorEvents[error.message])
      })
      .finally(() => {
        helpers.setSubmitting(false)
      })
  }

  const loginInitialValues = {
    identifier: '',
    password: '',
  }

  return (
    <div className={cn(sprinkles({ height: '100%' }), className)}>
      <Formik
        validateOnMount
        initialValues={loginInitialValues}
        validationSchema={loginValidation(t)}
        onSubmit={submit}
      >
        {({ isSubmitting, isValid }) => {
          return currentStep === 0 ? (
            <AnimatedWrapper className={sprinkles({ height: '100%' })}>
              <FlowHeader
                onBackButtonClick={() => {
                  back()
                  handleClick('Login', 'Back', touchPoint)
                }}
              />

              <div className={cn(loginBody, loginBodyMaxWidth)}>
                <Form>
                  <Heading2 className={sprinkles({ paddingBottom: 'xl' })}>
                    {t('auth-area:LoginWithMail_Title')}
                  </Heading2>
                  <div className={marginBottom.m}>
                    <FormikInput
                      label=""
                      name="identifier"
                      placeholder={t('auth-area:Input_Email_Placeholder')}
                      type="email"
                    />
                  </div>
                  <div>
                    <FormikInput
                      label=""
                      name="password"
                      placeholder={t('auth-area:LoginWithMail_Input_Password_Placeholder')}
                      type="password"
                    />
                  </div>
                  <div className={marginTop.m}>
                    <TextLinkButton
                      label={t('auth-area:Login_ToRecoverPassword')}
                      type="button"
                      onClick={() => {
                        changeFlow(Flow.recover)
                        handleClick('Login', 'ToRecoverPassword')
                      }}
                    />
                  </div>
                  <Button
                    fullWidth
                    className={marginTop.xl}
                    disabled={!isValid || isSubmitting}
                    label={t('auth-area:LoginWithMail_CTA')}
                    type="submit"
                  />
                </Form>
              </div>

              <FlowFooter
                ctaLabel={t('auth-area:ToSignUp_CTA')}
                description={t('auth-area:ToSignUp_Description')}
                handleCtaClick={() => {
                  changeFlow(Flow.signUp)
                  handleClick('Login', 'ToSignUp', touchPoint)
                }}
              />
            </AnimatedWrapper>
          ) : (
            <Completed touchPoint={touchPoint} onConfirm={complete} />
          )
        }}
      </Formik>

      <ToastError
        description={errorDescriptions[requestError] && t(errorDescriptions[requestError])}
        error={!!requestError}
        setError={() => setRequestError(null)}
        title={errorTitles[requestError] && t(errorTitles[requestError])}
      />
    </div>
  )
}

const Completed = ({ onConfirm, touchPoint }) => (
  <AnimatedWrapper>
    <CompletedFlowStepContent
      description="auth-area:Login_Success_Description"
      title="auth-area:Login_Success_Title"
      onConfirm={onConfirm}
    />
  </AnimatedWrapper>
)
