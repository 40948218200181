import { Form, Formik, FormikHelpers } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { AnimatedWrapper } from '@/components/AnimatedWrapper'
import { useAuthentication } from '@/context/AuthProvider'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { SignUpError } from '@/utils/auth/auth-client'
import { isValidUserFor } from '@/utils/third-party/recaptcha/api'
import { handleClick, handleShow } from '@/utils/tracking/eventsHandlers'

import { UserAuthTouchPoint } from '../..'
import { CompletedFlowStepContent } from '../CompletedFlowStepContent'
import { Flow } from '../Flow'

import { StepEmail, stepEmailInitialValues, stepEmailValidation } from './steps/StepEmail'
import { StepPassword, stepPasswordInitialValues, stepPasswordValidation } from './steps/StepPassword'

const errorEvents: Record<keyof typeof SignUpError, string> = {
  ALREADY_EXISTS: 'ExistingAccount',
  ALREADY_LOGGED_IN: 'GenericError',
  GENERIC: 'GenericError',
  TOO_SIMPLE_PASSWORD: 'EasyPassword',
}

type RegisterValidationSchema = Yup.InferType<
  ReturnType<typeof stepEmailValidation> & ReturnType<typeof stepPasswordValidation>
>

type Props = {
  back: () => void
  changeFlow: (flow: Flow) => void
  className?: string
  complete: () => void
  touchPoint: UserAuthTouchPoint
}

export const SignUpFlow: React.FC<Props> = ({ back, changeFlow, className, complete, touchPoint }) => {
  const { t } = useTranslation()
  const { registerUser } = useAuthentication()
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const [responseError, setResponseError] = useState<SignUpError | null>(null)

  const stepsValidation = [stepEmailValidation(t), stepPasswordValidation(t)]

  const submit = (values: RegisterValidationSchema, helpers: FormikHelpers<RegisterValidationSchema>) => {
    handleClick('SignUp', 'Submit', touchPoint)
    helpers.setSubmitting(true)
    isValidUserFor('registration')
      .then(() => {
        registerUser({
          identifier: values.email,
          password: values.password,
        })
          .then(() => {
            handleShow('SignUp', 'Success', touchPoint)
          })
          .then(() => complete && setCurrentStepIndex(2))
          .catch((error) => {
            setResponseError(error.message)
            handleShow('AuthError', errorEvents[error.message])
          })
      })
      .catch((error) => {
        setResponseError(error.message)
        handleShow('AuthError', errorEvents[error.message])
      })
      .finally(() => {
        helpers.setSubmitting(false)
      })
  }

  useEffect(() => {
    setResponseError(null)
  }, [currentStepIndex])

  return (
    <div className={className}>
      <Formik
        validateOnMount
        initialValues={{
          ...stepEmailInitialValues,
          ...stepPasswordInitialValues,
        }}
        validationSchema={stepsValidation[currentStepIndex]}
        onSubmit={submit}
      >
        <Form className={sprinkles({ display: 'flex', flex: 1, flexDirection: 'column' })}>
          {currentStepIndex === 0 ? (
            <StepEmail
              back={back}
              goToLogin={() => changeFlow(Flow.passwordLogin)}
              next={() => setCurrentStepIndex(1)}
              touchPoint={touchPoint}
            />
          ) : currentStepIndex === 1 ? (
            <StepPassword
              back={() => setCurrentStepIndex(0)}
              clearError={() => setResponseError(null)}
              error={responseError}
              goToLogin={() => {
                changeFlow(Flow.passwordLogin)
                handleClick('AuthError', 'ToLogin')
              }}
              touchPoint={touchPoint}
            />
          ) : (
            <AnimatedWrapper>
              <CompletedFlowStepContent
                description="auth-area:Signup_Success_Description"
                title="auth-area:Signup_Success_Title"
                onConfirm={complete}
              />
            </AnimatedWrapper>
          )}
        </Form>
      </Formik>
    </div>
  )
}
