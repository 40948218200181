import { BodyM, Button, Heading2 } from '@casavo/base-ui'
import cn from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

import { AnimatedWrapper } from '@/components/AnimatedWrapper'
import TextInput from '@/components/TextInput'
import { Translated } from '@/components/Translated'
import { useAuthentication } from '@/context/AuthProvider'
import { ToastError } from '@/design-lib/components/Toast/ToastError'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { RecoverPasswordVerifyError } from '@/utils/auth/auth-client'
import { handleClick, handleFormFieldSet, handleShow } from '@/utils/tracking/eventsHandlers'

import { FlowHeader } from '../../../FlowHeader'
import { loginBody, loginBodyMaxWidth } from '../../styles.css'
import { PasswordRecoveryStep } from '../PasswordRecoveryStep'

const errorTitles: Record<keyof typeof RecoverPasswordVerifyError, string> = {
  GENERIC: 'common:form-error',
  INVALID_VERIFICATION_CODE: 'auth-area:RecoverPassword_Error_InvalidCode_Title',
  NOT_STARTED: 'common:form-error',
}

const errorDescriptions: Record<keyof typeof RecoverPasswordVerifyError, string> = {
  GENERIC: undefined,
  INVALID_VERIFICATION_CODE: 'auth-area:RecoverPassword_Error_InvalidCode_Description',
  NOT_STARTED: undefined,
}

const errorEvents: Record<keyof typeof RecoverPasswordVerifyError, string> = {
  GENERIC: 'GenericError',
  INVALID_VERIFICATION_CODE: 'InvalidCode',
  NOT_STARTED: 'GenericError',
}

export const StepCode: PasswordRecoveryStep = ({ back, className, next }) => {
  const { t } = useTranslation()
  const { recoverPassword } = useAuthentication()
  const [recoverCode, setRecoverCode] = useState<string>('')
  const [error, setError] = useState<RecoverPasswordVerifyError | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <AnimatedWrapper className={className}>
      <FlowHeader
        onBackButtonClick={() => {
          handleClick('RecoverPassword', 'BackToReset')
          back()
        }}
      />
      <div className={cn(loginBody, loginBodyMaxWidth)}>
        <Heading2 className={sprinkles({ marginBottom: 'xl' })}>
          <Translated i18Key={'auth-area:RecoverPassword_Code_Title'} />
        </Heading2>
        <BodyM className={sprinkles({ marginBottom: 'xl' })} color="greyscale500">
          <Translated i18Key={'auth-area:RecoverPassword_Code_Sendto'} />
          <b>&nbsp;{recoverPassword.recoveringEmail()}</b>
        </BodyM>
        <BodyM className={sprinkles({ marginBottom: 'l' })}>
          <Translated i18Key="auth-area:RecoverPassword_Code_Description" />
        </BodyM>

        <TextInput
          noBorder
          type="text"
          value={recoverCode}
          onBlur={() => recoverCode && handleFormFieldSet('RecoverPassword', 'InsertCode')}
          onChange={(e) => setRecoverCode(e.currentTarget.value)}
        />

        <Button
          fullWidth
          className={sprinkles({ marginTop: { desktop: 'l', mobile: 'auto' } })}
          disabled={!recoverCode || isSubmitting}
          label={t('auth-area:RecoverPassword_Code_CTA')}
          onClick={() => {
            handleClick('RecoverPassword', 'ConfirmCode')
            setIsSubmitting(true)
            recoverPassword
              .verify(recoverCode)
              .then(next)
              .then(() => handleShow('RecoverPassword', 'SetNewPassword'))
              .catch((e) => {
                setError(e.message)
                handleShow('AuthError', errorEvents[e.message])
              })
              .finally(() => {
                setIsSubmitting(false)
              })
          }}
        />

        <ToastError
          description={errorDescriptions[error] && t(errorDescriptions[error])}
          error={!!error}
          setError={() => setError(null)}
          title={errorTitles[error] && t(errorTitles[error])}
        />
      </div>
    </AnimatedWrapper>
  )
}
