import { BodyM, Button } from '@casavo/base-ui'

import { Divider } from '@/design-lib/components/Divider'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'

type Props = {
  ctaLabel: string
  description: string
  handleCtaClick: VoidFunction
}

export const FlowFooter: React.FC<Props> = ({ ctaLabel, description, handleCtaClick }) => {
  return (
    <div className={sprinkles({ marginTop: 'auto' })}>
      <Divider colorVariant="greyscale300" />
      <div
        className={sprinkles({
          alignItems: 'center',
          display: 'flex',
          flexDirection: { desktop: 'column' },
          gap: { desktop: 's' },
          justifyContent: 'space-around',
        })}
      >
        <BodyM>{description}</BodyM>
        <Button
          className={sprinkles({ flexShrink: 0 })}
          label={ctaLabel}
          styleVariant="tertiary"
          onClick={handleCtaClick}
        />
      </div>
    </div>
  )
}
