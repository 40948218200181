import cn from 'classnames'
import { motion } from 'framer-motion'

import { fadeInAnimation } from '@/components/framer-motion-variants'

import { animatedWrapper } from './styles.css'

export const AnimatedWrapper: React.FC<{ className?: string }> = ({ children, className }) => (
  <motion.div
    animate="animate"
    className={cn(animatedWrapper, className)}
    exit="exit"
    initial="initial"
    variants={fadeInAnimation}
  >
    {children}
  </motion.div>
)
