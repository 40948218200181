import { Button } from '@casavo/base-ui'
import getConfig from 'next/config'
import Image from 'next/image'
import React from 'react'

import Chevron from '@/components/Icon/Chevron'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'

type Props = {
  onBackButtonClick?: VoidFunction
}
export const FlowHeader: React.FC<Props> = ({ onBackButtonClick }) => {
  const {
    publicRuntimeConfig: { casavoUiCdn },
  } = getConfig()

  if (!onBackButtonClick) {
    return (
      <header className={sprinkles({ paddingBottom: '2xl', paddingTop: { desktop: 'l' } })}>
        <Image alt="" height={30} src={`${casavoUiCdn}/logo/main-wordmark-black-mobile.svg`} width={110} />
      </header>
    )
  }

  return (
    <div
      className={sprinkles({
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '4xl',
        transform: 'translateZ(0)',
      })}
    >
      <Button
        ariaLabel="back"
        className={sprinkles({ left: 0, position: 'absolute', top: { desktop: 'm' } })}
        colorVariant="light"
        Icon={() => <Chevron direction="left" />}
        shapeVariant="round"
        styleVariant="primary"
        onClick={onBackButtonClick}
      />

      <Image alt={'casavo'} height={30} src={`${casavoUiCdn}/logo/Casavo_Symbol_Black_MONO.svg`} width={110} />
    </div>
  )
}
