import { AnimatePresence, motion } from 'framer-motion'
import useTranslation from 'next-translate/useTranslation'
import { Dispatch, SetStateAction } from 'react'

import { useDismiss } from '@/hooks/useDismiss'

import { Toast, toastAnimation } from '..'
import { fixedPositioningToast } from '../styles.css'

type Props = {
  description?: string
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  title?: string
}

export const ToastError: React.FC<Props> = ({ description, error, setError, title }) => {
  const { t } = useTranslation()
  const titleDefault = title ?? t('common:form-error')
  useDismiss(error, setError)

  return (
    <AnimatePresence initial={false}>
      {error && (
        <motion.div
          animate="animate"
          className={fixedPositioningToast}
          exit="exit"
          initial="initial"
          variants={toastAnimation}
        >
          <Toast description={description} styleType="error" title={titleDefault} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
