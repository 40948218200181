import { BodyM, Button, Heading2 } from '@casavo/base-ui'
import cn from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import { FormEventHandler, useState } from 'react'
import * as Yup from 'yup'

import { AnimatedWrapper } from '@/components/AnimatedWrapper'
import TextInput from '@/components/TextInput'
import { Translated } from '@/components/Translated'
import { useAuthentication } from '@/context/AuthProvider'
import { ToastError } from '@/design-lib/components/Toast/ToastError'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { marginBottom } from '@/shared-styles/styles.css'
import { RecoverPasswordStartError } from '@/utils/auth/auth-client'
import { handleClick, handleShow } from '@/utils/tracking/eventsHandlers'

import { FlowHeader } from '../../../FlowHeader'
import { loginBody, loginBodyMaxWidth } from '../../styles.css'
import { PasswordRecoveryStep } from '../PasswordRecoveryStep'

const errorTitles: Record<keyof typeof RecoverPasswordStartError, string> = {
  GENERIC: 'common:form-error',
}

const errorDescriptions: Record<keyof typeof RecoverPasswordStartError, string> = {
  GENERIC: undefined,
}

const errorEvents: Record<keyof typeof RecoverPasswordStartError, string> = {
  GENERIC: 'GenericError',
}

export const StepEmail: PasswordRecoveryStep = ({ back, className, next }) => {
  const { t } = useTranslation()
  const { recoverPassword } = useAuthentication()
  const [recoverEmail, setRecoverEmail] = useState<string>('')
  const [emailError, setEmailError] = useState('')
  const [requestError, setRequestError] = useState<RecoverPasswordStartError | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const isEmailValid = (email: string) =>
    Yup.string()
      .required(t('common:required-field-message'))
      .email(t('common:invalid-email-message'))
      .validate(email)
      .then(() => setEmailError(''))
      .catch((e) => setEmailError(e.message))

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    handleClick('RecoverPassword', 'SendCode')
    setIsSubmitting(true)
    await recoverPassword
      .start(recoverEmail)
      .then(next)
      .catch((e) => {
        setRequestError(e.message)
        handleShow('AuthError', errorEvents[e.message])
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <AnimatedWrapper className={className}>
      <FlowHeader
        onBackButtonClick={() => {
          handleClick('RecoverPassword', 'Back')
          back()
        }}
      />
      <form className={cn(loginBody, loginBodyMaxWidth)} onSubmit={onSubmit}>
        <Heading2 className={sprinkles({ marginBottom: 'xl' })}>
          <Translated i18Key={'auth-area:RecoverPassword_Title'} />
        </Heading2>
        <BodyM className={sprinkles({ marginBottom: 'xl' })} color="greyscale500">
          <Translated i18Key={'auth-area:RecoverPassword_Description'} />
        </BodyM>

        <div className={marginBottom['2xl']}>
          <TextInput
            className={sprinkles({ border: 0 })}
            error={emailError}
            name="email"
            placeholder={t('auth-area:Input_Email_Placeholder')}
            type="email"
            value={recoverEmail}
            onBlur={(e) => isEmailValid(e.currentTarget.value)}
            onChange={(e) => {
              setRecoverEmail(e.currentTarget.value)
              isEmailValid(e.currentTarget.value)
            }}
          />
        </div>

        <Button
          fullWidth
          className={sprinkles({ marginTop: 'auto' })}
          disabled={!recoverEmail || !!emailError || isSubmitting}
          label={t('auth-area:RecoverPassword_CTA')}
          type="submit"
        />

        <ToastError
          description={errorDescriptions[requestError] && t(errorDescriptions[requestError])}
          error={!!requestError}
          setError={() => setRequestError(null)}
          title={errorTitles[requestError] && t(errorTitles[requestError])}
        />
      </form>
    </AnimatedWrapper>
  )
}
