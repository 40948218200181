import { Dispatch, SetStateAction, useEffect } from 'react'

export const useDismiss = (state: boolean, setState: Dispatch<SetStateAction<boolean>>, time = 3500) => {
  useEffect(() => {
    const dismiss = setTimeout(() => {
      setState(false)
    }, time)

    return () => {
      clearTimeout(dismiss)
    }
  }, [state, setState])
}
