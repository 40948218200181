import BaseSvg, { Props } from '../BaseSvg'

const Email: React.FC<Props> = (props) => (
  <BaseSvg {...props}>
    <g clipPath="url(#a)">
      <path
        d="M14.6666 4.00002c0-.73333-.6-1.33333-1.3333-1.33333H2.66659c-.73334 0-1.33334.6-1.33334 1.33333V12c0 .7334.6 1.3334 1.33334 1.3334H13.3333c.7333 0 1.3333-.6 1.3333-1.3334V4.00002Zm-1.3333 0L7.99992 7.33335 2.66659 4.00002H13.3333Zm0 7.99998H2.66659V5.33335l5.33333 3.33334 5.33338-3.33334V12Z"
        fill="#1D1D1B"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h16v16H0z" fill="#fff" />
      </clipPath>
    </defs>
  </BaseSvg>
)

export default Email
