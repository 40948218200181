import { BodyM, Button, Heading2 } from '@casavo/base-ui'
import cn from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import getConfig from 'next/config'
import Image from 'next/image'
import React from 'react'

import { Translated } from '@/components/Translated'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'

import { FlowHeader } from '../FlowHeader'

import { completedFlowStepContentStyle } from './styles.css'

type Props = {
  description: string
  onConfirm?: VoidFunction
  title: string
}

export const CompletedFlowStepContent: React.FC<Props> = ({ description, onConfirm, title }) => {
  const { t } = useTranslation()
  const {
    publicRuntimeConfig: { imageBasePath },
  } = getConfig()

  return (
    <>
      <FlowHeader />
      <div
        className={sprinkles({
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
      >
        <div>
          <div className={sprinkles({ marginBottom: 'm', textAlign: 'center' })}>
            <Image height={96} src={`${imageBasePath}/images/concepts/support-loan.svg`} width={96} />
          </div>

          <Heading2
            className={sprinkles({
              marginBottom: 'xl',
            })}
          >
            <Translated i18Key={title} />
          </Heading2>
        </div>
        <BodyM className={cn(completedFlowStepContentStyle, sprinkles({ paddingBottom: '3xl' }))} color="greyscale500">
          <Translated i18Key={description} />
        </BodyM>

        <Button
          className={sprinkles({ marginBottom: 'l' })}
          label={t('auth-area:Login_Success_CTA')}
          minWidth={200}
          styleVariant="secondary"
          onClick={onConfirm}
        />
      </div>
    </>
  )
}
