import { useState } from 'react'

import { UserAuthTouchPoint } from '../../UserAuthentication'

import { PasswordRecoveryStep } from './PasswordRecoveryStep'
import { StepCode } from './steps/StepCode'
import { StepEmail } from './steps/StepEmail'
import { StepPassword } from './steps/StepPassword'
import { StepPasswordSaved } from './steps/StepPasswordSaved'

type Props = {
  back: () => void
  className?: string
  complete: () => void
  touchPoint: UserAuthTouchPoint
}

export const PasswordRecoveryFlow: React.FC<Props> = ({ back, className, complete, touchPoint }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const steps: PasswordRecoveryStep[] = [StepEmail, StepCode, StepPassword, StepPasswordSaved]

  const isFirstStep = () => currentStepIndex === 0
  const isLastStep = () => currentStepIndex >= steps.length - 1
  const nextStep = () => setCurrentStepIndex(currentStepIndex + 1)
  const previousStep = () => setCurrentStepIndex(currentStepIndex - 1)

  const CurrentStep = steps[currentStepIndex]
  return (
    <CurrentStep
      back={() => (isFirstStep() ? back() : previousStep())}
      className={className}
      next={() => (isLastStep() ? complete() : nextStep())}
      touchPoint={touchPoint}
    />
  )
}
