import { BodyM } from '@casavo/base-ui'
import getConfig from 'next/config'
import Image from 'next/image'

import { Translated } from '@/components/Translated'
import { sprinkles } from '@/design-lib/style-theme/sprinkles.css'
import { EnabledSocialLoginProvider } from '@/utils/auth/auth-client'

import { socialLoginButton } from './styles.css'

export const SocialLoginButton: React.FC<{ onClick: () => void; provider: EnabledSocialLoginProvider }> = ({
  onClick,
  provider,
}) => {
  const {
    publicRuntimeConfig: { imageBasePath },
  } = getConfig()

  return (
    <button className={socialLoginButton[provider]} name="provider" type="submit" value={provider} onClick={onClick}>
      <div className={sprinkles({ alignItems: 'center', display: 'flex', gap: 'm', margin: '0 auto' })}>
        <Image alt="" height={23} src={`${imageBasePath}/images/social-login/${provider}.svg`} width={23} />
        <BodyM strong color="greyscale500">
          <Translated i18Key={`auth-area:LoginPage_Button_${provider}`} />
        </BodyM>
      </div>
    </button>
  )
}
