import getConfig from 'next/config'
import Image from 'next/image'

type Props = {
  alt?: string
  className?: string
  height: number
  name: string
  width: number
}
export const ConceptImage: React.FC<Props> = ({ alt = '', className, height, name, width }) => {
  const {
    publicRuntimeConfig: { imageBasePath },
  } = getConfig()

  return (
    <Image
      alt={alt}
      className={className}
      height={height}
      src={`${imageBasePath}/images/concepts/${name}.svg`}
      width={width}
    />
  )
}
